import React from "react";

export default function HomeSection5() {
    return (
        <div className="HomeSection5">
            <h1 className="heading">Step In</h1>
            <br />
            <p className="text HomeSection5-Text" >Ready to level up your tech interviews? Step into the future of recruitment with Rekruit. Go ahead, make your move!</p>
            <br />
            <a href="/firm-onboarding"><button className="btn btn-1 btn-rounded HomeSection5-Btn">Sign Up Now!</button></a>
        </div>
    )
}