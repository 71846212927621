import React from "react";

export default function HomeSection2() {
    return (
        <div id="section-2">
            <div className="HomeSection2">
                <h2 className="heading">Redefining Interactions</h2>

                <div className="HomeSection2-Items">
                    <p className="text HomeSection2-Item">Welcome to Rekruit – the future of tech recruitment. We're not just another job platform; we're revolutionizing how tech interviews are conducted. Join us in transforming the hiring process with cutting-edge technology and expert insights.</p>
                    <p className="text HomeSection2-Item">Experience the Rekruit platform: where cutting-edge security meets top-of-the-line technology, all wrapped in modern, simplistic elegance. Professionalism has taken on a whole new aura.</p>
                </div>
            </div>
        </div>
    )
}