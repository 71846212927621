import React from "react";

export default function HomeSection3() {
    return (
        <div className="HomeSection3">
            <div className="HomeSection3-Items">
                <img src="src-bg-sec-4.webp" className="rounded-img sec3-img"></img>
                <div className="HomeSection3-Item1">
                    <h1 className="heading-2">Redefining Tech Interviews: One Milestone at a Time</h1>
                    <p className="text">Regardless of whether you‘re a tech startup in a garage or Google, you want the cream of the crop. And with Rekruit, you‘ll get it. Get ready to revolutionize your tech interviews.</p>
                </div>
            </div>

            <br />
            <br />

            <div className="HomeSection3-Items">

                <div className="HomeSection3-Item2">
                    <h1 className="heading-2">Easier Access, Better Candidates, Excellent Security</h1>
                    <p className="text">Our platform ensures that you have the pick of the litter in tech talent. Worries about data protection and security? We got ‘em handled. You focus on picking your dream team.</p>
                </div>

                <img src="sec3-pic2.png" className="rounded-img sec3-img sec3img-2" ></img>
            </div>

        </div>
    )
}